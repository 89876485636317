import { redirect } from '@remix-run/node';
import { useNavigate, } from '@remix-run/react';
import { useEffect, } from 'react';
import { useRealmApp } from '~/useRealmApp';
import LogoImage from '~/images/logo.png';
import MainLogoImage from '~/images/main_logo.png';
import Spinner from '~/components/Spinner';

export default function index() {
  const navigate = useNavigate();
  const app = useRealmApp();

  useEffect(() => {
    if (app.currentUser) {
      navigate('/main');
    } else {
      navigate('/trial');
    }
  }, []);

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center gap-5">
      <img className="object-contain" src={MainLogoImage} />
      <Spinner color="ffffff" />
    </div>
  );
}
